import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const TimBurtonPage = (props) => (
  <Layout>
    <SEO title="Tim Burton Page" />
    <Link className="back-link" to="/">Go back to the homepage</Link>
    <h1 style={{marginTop: `50px`, textAlign: 'center'}}>Photoshop Illustration</h1>
    <Img fluid={props.data.imageTimBurtonHeader.childImageSharp.fluid} />
    <div style={{maxWidth: `700px`, margin: `0 auto`}}>
        <p>
            This was a self initiated project with the aim of practising with Adobe Photoshop's brushes and a wacom tablet. The illustration is a portrait of film director Tim Burton.
        </p>
    </div>
    <Img fluid={props.data.imageTimBurtonSplitImage.childImageSharp.fluid} />
    <div style={{maxWidth: `700px`, margin: `0 auto 100px`}}>
        <p>
            I started by creating a pencil sketch on paper. This sketch was then scanned in and I used Photoshop to add tonal shading and then colouring. The image below is the final version.
        </p>
    </div>
    <div style={{maxWidth: `500px`, margin: `0 auto`}}>
        <Img fluid={props.data.imageTimBurtonFinal.childImageSharp.fluid} />
    </div>
    <div style={{maxWidth: `700px`, margin: `50px auto 0`}}>
        <p>
            During the process of creating the image I recorded my screen. An edited video of the creation of the image can be seen below.
        </p>
    </div>
    <div style={{maxWidth: `560px`, margin: `0 auto`, padding: `40px 0`}}>
        <iframe src="https://www.youtube.com/embed/ai5He55Eg84" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
    <h2>View other pieces of work...</h2>
    <div className="work" style={{display: `flex`, flexWrap: `wrap`, justifyContent: `space-between`, alignItems: `center`, marginTop: `40px`}}>
        <a href="/quantum/" style={{height: `300px`, marginBottom: `0%`}}>
            <div className="work-1" style={{height: `300px`}}></div>
        </a>
        <a href="/icons/" style={{height: `300px`, marginBottom: `0%`}}>
            <div className="work-4" style={{height: `300px`}}></div>
        </a>
        <a href="/otis/" style={{height: `300px`, marginBottom: `0%`}}>
            <div className="work-5" style={{height: `300px`}}></div>
        </a>
    </div>
    <Link className="back-link" to="/" style={{marginTop: `20px`, display: `block`}}>Go back to the homepage</Link>
  </Layout>
)

export default TimBurtonPage

export const pageQuery = graphql`
  query {
    imageTimBurtonHeader: file(relativePath: { eq: "tim-burton-header.png" }) {
      ...fluidImage
    }
    imageTimBurtonSplitImage: file(relativePath: { eq: "tim-burton-split.png" }) {
      ...fluidImage
    }
    imageTimBurtonFinal: file(relativePath: { eq: "tim-burton-final.jpg" }) {
      ...fluidImage
    }
  }
`